<template>
      <div v-if="loading" class="container-endorsement ol-home-endorsement-list">
        <SkeletonLoader />
      </div>
      <div v-else class="container-endorsement ol-home-endorsement-list">
        <div class="card-endorsement" >
          <div class="card-endorsement-header">
              <img :src="require('@/assets/images/kasur/@infogresik.png')" />
          </div>
          <div class="card-endorsement-body">
            <div class="user">
              <img :src="require('@/assets/images/kasur/img-infogresik.jpg')" />
              <div class="user-info">
                <h5 style="font-size:11px;">info GRESIK</h5>
                <small>@infogresik</small>
              </div>
            </div>
          </div>
        </div>
        <div class="card-endorsement" >
          <div class="card-endorsement-header">
              <img :src="require('@/assets/images/kasur/@muri_org.png')" />
          </div>
          <div class="card-endorsement-body">
            <div class="user">
              <img :src="require('@/assets/images/kasur/img-muri_org.jpg')" />
              <div class="user-info">
                
                <h5 style="font-size:11px;">Museum Rekor-Dunia</h5>
                <small>@muri_org</small>
              </div>
            </div>
          </div>
        </div>
        <div class="card-endorsement" >
          <div class="card-endorsement-header">
              <img :src="require('@/assets/images/kasur/@anyacahyara.png')" />
          </div>
          <div class="card-endorsement-body">
            <div class="user">
              <img :src="require('@/assets/images/kasur/img-anyacahyara.jpg')" />
              <div class="user-info">
                <h5 style="font-size:11px;">Anya</h5>
                <small>@anyacahyara</small>
              </div>
            </div>
          </div>
        </div>
        <div class="card-endorsement" >
          <div class="card-endorsement-header">
              <img :src="require('@/assets/images/kasur/@eh.olahraga.yuk.png')" />
          </div>
          <div class="card-endorsement-body">
            <div class="user">
              <img :src="require('@/assets/images/kasur/img-eh.olahraga.yuk.jpg')" />
              <div class="user-info">
                <h5 style="font-size:11px;">Eh Olahraga Yuk</h5>
                <small>@eh.olahraga.yuk</small>
              </div>
            </div>
          </div>
        </div>
        <div class="card-endorsement" >
          <div class="card-endorsement-header">
              <img :src="require('@/assets/images/kasur/@ferra_agustina.png')" />
          </div>
          <div class="card-endorsement-body">
            <div class="user">
              <img :src="require('@/assets/images/kasur/img-ferra_agustina.jpg')" />
              <div class="user-info">
                <h5 style="font-size:11px;">Ferra Agustion</h5>
                <small>@ferra_agustina</small>
              </div>
            </div>
          </div>
        </div>
        <div class="card-endorsement" >
          <div class="card-endorsement-header">
              <img :src="require('@/assets/images/kasur/@wisatagresik.png')" />
          </div>
          <div class="card-endorsement-body">
            <div class="user">
              <img :src="require('@/assets/images/kasur/img-wisatagresik.jpg')" />
              <div class="user-info">
                <h5 style="font-size:11px;">WISATA GRESIK</h5>
                <small>@wisatagresik</small>
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  import SkeletonLoader from "@/components/loading/skeletonLoader";
  import {mapActions,mapGetters} from "vuex";
  
  export default {
    name: "Testimonial",
    components:{
      SkeletonLoader
    },
    data() {
      return {
        loading:false
      };
    },
    async mounted() {
        $(".container-endorsement").slick({
            slidesToScroll: 1,
            slidesToShow: 5,
            dots: !1,
            autoplay: !0,
            autoplaySpeed: 5e3,
            arrows: !0,
            pauseOnFocus: !1,
            infinite: !0,
            speed: 500,
            fade: !1,
            responsive: [{
            breakpoint: 891,
            settings: {
                slidesToShow: 2,
            },
            }, {
            breakpoint: 550,
            settings: {
                slidesToShow: 2,
            },
            }],
        });
    },
    updated() {
      
    },
  };
  </script>
  
  <style lang="css" scoped>
  @import "../../assets/css/_endorsement.css";
  @import "../../assets/js/slick/slick.css";
  </style>
  