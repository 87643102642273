<template>
    <BaseLayout>
        <img  class="image-home-info banner-desktop" width="100%" :src="require('@/assets/images/kasur/banner-dekstop.jpg')" />
        <div class="ol-wrapper ol-wrapper--large">
            <div class="break-32 banner-desktop"></div>
            <h1 class="text-header">Olympic Springbed & Foam</h1>
            <div class="break-16"></div>
            <p style="text-align:center;" class="text-title">
                Hi! Kami adalah <b>kasur buatan Indonesia</b> dengan <b>kualitas Internasional</b>. Dengan formula terbaru dari Jerman, kasur kami mampu memberikanmu pengalaman tidur terbaik dengan daya topang tubuh dan kelembutan kain rajut yang luar biasa. 
            </p>
            <div class="break-32"></div>
        </div>
        <img  class="image-home-info banner-mobile" width="100%" :src="require('@/assets/images/kasur/banner.jpg')" />
        <div class="ol-wrapper ol-wrapper--large">
            <div class="break-32"></div>
            <div class="row no-gutters info-kelebihan">
                <div class="col-4">
                    <div class="row no-gutters" style="text-align:center">
                        <div class="col-lg-12"><img  class="image-home-info image-kelebihan" :src="require('@/assets/images/benefit/springbed-05.png')" /></div>
                        <div class="col-lg-12 text-title">100% Gratis Ongkir</div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row no-gutters" style="text-align:center">
                        <div class="col-lg-12"><img  class="image-home-info image-kelebihan" :src="require('@/assets/images/benefit/springbed-07.png')" /></div>
                        <div class="col-lg-12 text-title">Garansi Hingga 20 Tahun</div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row no-gutters" style="text-align:center">
                        <div class="col-lg-12"><img  class="image-home-info image-kelebihan" :src="require('@/assets/images/benefit/springbed-06.png')" /></div>
                        
                        <div class="col-lg-12 text-title">Sertifikat Muri & Top Brand</div>
                    </div>
                </div>
            </div>
            <div class="break-32"></div>
            <div class="ol-product-content add-fix">
                <aside class="ol-product-content__sidebar">
                    <div class="ol-product-content__sidebar-item">
                        <h4 class="ol-product-content__sidebar-item-title">Koleksi</h4>
                        <select v-model="filterCategories" class="ol-input" style="height:46px; background-color: #EAEDF0;border-radius:8px">
                            <option value="">Tampilkan Semua</option>
                            <option value="64">Olympic Springbed</option>
                            <option value="65">Olympic Foam</option>
                            <option value="66">Divan</option>
                        </select>
                    </div> 
                    <div class="ol-product-content__sidebar-item">
                        <h4 class="ol-product-content__sidebar-item-title">Ukuran</h4>
                        <select @change="filterProduct()" v-model="filterSearch" class="ol-input" style="height:46px; background-color: #EAEDF0;border-radius:8px">
                            <option value="">Tampilkan Semua</option>
                            <option value="90x195">90x195</option>
                            <option value="90x200">90x200</option>
                            <option value="120x200">120x200</option>
                            <option value="160x200">160x200</option>
                            <option value="180x200">180x200</option>
                        </select>
                    </div>  
                </aside>
                <div class="ol-product-content__center">
                    <ProductList :products="products" /> 
                </div>
            </div>
        </div>
        <div style="background-color:#E3EEF7;padding-top:32px;padding-bottom: 32px;">
            <div class="row" style="text-align: center;" >
                <p style="text-align: center;margin:auto;font-weight: bold;" class="text-subtitle">
                    Komitmen kami pada inovasi<br> terwujukan dengan beragam sertifikasi
                </p>
            </div>
            <div class="break-16"></div>
            <div class="row no-gutters info-sertifikat" >
                <div style="text-align:center;width:23%;">
                    <img  class="image-home-info image-sertifikat" :src="require('@/assets/images/kasur/top-brand.png')" />
                </div>
                <div style="text-align:center;width:23%;">
                    <img  class="image-home-info image-sertifikat" :src="require('@/assets/images/kasur/muri.png')" />
                </div>
                <div style="text-align:center;width:23%;">
                    <img  class="image-home-info image-sertifikat" :src="require('@/assets/images/kasur/ISPA.png')" />
                </div>
                <div style="text-align:center;width:20%;">
                    <img  class="image-home-info image-sertifikat-1"  :src="require('@/assets/images/kasur/silver.png')" />
                </div>
            </div>
        </div>
        <div class="ol-wrapper ol-wrapper--large">
            <div class="break-32"></div>
            <div class="row" style="text-align: center;font-weight: bold;" >
                <div class="text-subtitle" style="margin:auto">
                    <p>Kenal Lebih Dekat Olympic Springbed & Foam</p>
                    <div class="break-16"></div>
                    <a target="_blank" href="https://www.instagram.com/olympic.bed/"><img height="40px" :src="require('@/assets/images/kasur/followus.png')"></a>
                </div>
            </div>
            <div class="break-32"></div>
            <div class="row" style="text-align: center;" >
                <div class="animation text-subtitle">
                    <p>Kasur favorit  <span class="typed-text">{{ typedText }}</span><span class="cursor" :class="{ typing: isTyping }"></span></p>
                </div>
            </div>
            <div class="break-16"></div>
            <Testimonial />
            <Videoshort />
        </div>
        <div style="background-color:#E3EEF7;margin-top:32px;margin-bottom: 32px;">
            <div class="ol-wrapper ol-wrapper--large">
                <div class="break-32"></div>
                <div class="row">
                    <div class="col-lg-4 col-md-12">
                        <p style="text-align: center;margin:auto;" class="text-subtitle">
                            <b>FAQ</b>
                        </p>
                        <div class="break-16"></div>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="accordion">
                            <div class="accordion-item">
                                <button id="accordion-button-0" class="accordion" :aria-expanded="items[0].expanded" @click="toggleAccordion(0)">
                                <span class="accordion-title">Apa Olympic Springbed & Foam anti kempes & tahan lama?</span><span class="icon" aria-hidden="true"></span>
                                </button>
                                <div class="accordion-content">
                                    <p>
                                        Dengan formula terbaru dari Jerman dan seritifikasi Top Brand serta MURI, kualitas kasur kami terjamin dengan garansi pegas hingga 20 tahun. 
                                        <br>
                                        <br>
                                        
                                        Olympic Foam
                                        <ul style="list-style: circle;margin-left: 18px;">
                                            <li>Series Aquamarine Garansi 15 Tahun</li>
                                            <li>Series Coral Garansi 15 Tahun</li>
                                            <li>Series Hazel Garansi 15 Tahun</li>
                                        </ul>

                                        <br>
                                        Olympic Springbed
                                        <ul style="list-style: circle;margin-left: 18px;">
                                            <li>Series Pocket Garansi 20 Tahun</li>
                                            <li>Series Gold Garansi 20 Tahun</li>
                                            <li>Series Silver Garansi 15 Tahun</li>
                                            <li>Series 2 in 1 Garansi 15 Tahun</li>
                                            <li>Series Kids Garansi 15 Tahun</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <button id="accordion-button-1" class="accordion" :aria-expanded="items[1].expanded" @click="toggleAccordion(1)">
                                <span class="accordion-title">Bagaimana cara perawatan Olympic Springbed & Foam?</span><span class="icon" aria-hidden="true"></span>
                                </button>
                                <div class="accordion-content">
                                    <p>Meski bergaransi dan berkualitas, kamu juga perlu memperhatikan perawatan Olympic Springbed & Foam seperti berikut:
                                        <ul style="list-style: circle;margin-left: 18px;">
                                            <li>Usahakan kasur tidak langsung bersentuhan dengan lantai, beri divan / karpet sebagai alas.</li>
                                            <li>Putar posisi kasur secara horizontal setiap 6 bulan agar lebih awet dan tahan lama.</li>
                                            <li>Bersihkan kasur secara berkala agar higenis dan terhindar dari debu.</li>
                                            <li>Hindari kasur terkena banjir, air seni, tumpahan makanan, dan cairan lainnya.</li>
                                            <li>Jika ada kerusakan pada kasur, harap tidak direparasi oleh pihak luar selain pihak brand.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <button id="accordion-button-2" class="accordion" :aria-expanded="items[2].expanded" @click="toggleAccordion(2)">
                                <span class="accordion-title">Mengapa harus membeli Olympic Springbed & Foam?</span><span class="icon" aria-hidden="true"></span>
                                </button>
                                <div class="accordion-content">
                                    <p>
                                        <ul style="list-style: circle;margin-left: 18px;">
                                            <li>Kasur kami merupakan buatan lokal dengan kualitas internasional.</li>
                                            <li>Memiliki desain ergonomis yang disesuaikan dengan kebutuhan tidur orang Indonesia.</li>
                                            <li>Terbuat dari bahan berkualitas, sehingga bergaransi hingga 20 tahun.</li>
                                            <li>Harga terjangkau dan dapat dibeli di Mitra Olympic seluruh Indonesia.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="break-32"></div>
            </div>
        </div>
        <div class="ol-wrapper ol-wrapper--large">
            <div >
                <p style="font-weight: bold;">Cara Memilih Kasur Busa</p>

                <p>Memilih kasur busa membutuhkan pemikiran yang matang. Kasur menjadi furniture jangka panjang yang mempengaruhi kualitas tidur dan kesehatan tubuh. &#160;Dalam memilih kasur busa, pertimbangan seperti merek, harga, material, dan tingkat kelembutannya sangat penting. Panduan lengkap cara memilih kasur busa yang tepat:</p>
                <br>
                <p style="font-weight: bold;">Pertimbangkan Material Foam atau Spon</p>

                <p>Cari foam yang lentur dan empuk. Foam yang sedikit lebih keras akan menjadi lebih awet dan ekonomis dalam jangka panjang.</p>
                <br>
                <p style="font-weight: bold;">Kepadatan Kasur Busa</p>

                <p>Pilihlah kasur busa dengan tingkat kepadatan yang tinggi. Semakin berat kasur busa, semakin padat materialnya, yang berarti akan lebih awet dan nyaman.</p>
                <br>
                <p style="font-weight: bold;">Cepat Kembali Seperti Semula</p>

                <p>Kasur busa yang cepat kembali ke bentuk semula menunjukkan kualitas yang baik. Ini menunjukkan durabilitas dan kenyamanan yang berkelanjutan.</p>
                <br>
                <p style="font-weight: bold;">Jangan Hanya Melihat Cover</p>

                <p>Jangan terkecoh oleh lapisan luar yang mewah. Fokuslah pada kualitas foam atau spons kasur busa, karena inilah yang terpenting untuk meningkatkan kenyamanan.</p>
                <br>
                <p style="font-weight: bold;">Pilih Tingkat Ketebalan Kasur Busa</p>

                <p>Sesuaikan tingkat ketebalan dengan kebutuhan dan selera. Kasur busa yang tebal biasanya lebih nyaman dan awet. Kasur busa Olympic Foam tersedia dengan tingkat ketebalan 15cm dan 20cm.</p>
                <br>
                <p style="font-weight: bold;">Perhatikan Masa Garansi</p>

                <p>Semakin lama masa garansi, semakin berkualitas produknya. Pastikan untuk memahami apa saja yang termasuk dalam garansi tersebut dan cara menggunakan garansi.</p>
                <br>
                <p style="font-weight: bold;">Cek Merek dan Harga</p>

                <p>Merek terpercaya biasanya menawarkan produk berkualitas seperti Olympic Foam. Dari segi harga yang masih bersaing tetapi secara kualitas dan tingkat keawetannya dapat diandalkan.</p>
                <br>
                <p style="font-weight: bold;">Cara Memilih Kasur Springbed Berkualitas Periksa Ukuran Kasur</p>

                <p>Ukuran kasur springbed menjadi salah satu faktor penting dalam cara memilih kasur springbed. Untuk ruangan besar, ukuran king size (180 cm x 200 cm) atau super king size (200 cm x 200 cm) bisa menjadi pilihan tepat. Sedangkan untuk ruangan sedang bisa memilih double size (120 cm x 200cm) atau queen size (160cm x 200cm). Ada juga ukuran single size (90cm x 200cm) untuk 1 orang saja.</p>
                <br>
                <p style="font-weight: bold;">Periksa Ketebalan Kasur</p>

                <p>Ketebalan kasur adalah salah satu faktor utama dalam menentukan kenyamanan. Kasur springbed yang tebal biasanya lebih empuk untuk ditiduri. Kasur springbed berkualitas biasanya memiliki 140 buah per di setiap perseginya, tetapi jika ingin kualitas yang lebih tinggi, tentu harus mencari jumlah per yang lebih banyak.</p>
                <br>
                <p style="font-weight: bold;">Ketahui Jenis Per Kasur</p>

                <p>Ada dua jenis per utama yang digunakan dalam pembuatan kasur spring bed, yaitu: bonnell spring yang terhubung dari satu per ke per yang lain sehingga dapat bergerak bersamaan ketika terkena tekanan tubuh dan pocket spring yang dapat menjaga kasur dari goncangan ketika terkena tekanan tubuh karena pocket spring berfungsi secara mandiri.</p>
                <br>
                <p style="font-weight: bold;">Cara Merawat Kasur Busa</p>

                <p>Kasur busa menjadi pilihan populer bagi banyak orang, tetapi bagaimana cara merawat kasur busa agar tetap awet dan bersih. Panduan lengkap tentang cara merawat kasur busa yang dapat membantu memastikan tidur yang nyaman dan sehat.</p>
                <br>
                <p style="font-weight: bold;">Gunakan Box Spring atau Dipan yang Sesuai</p>

                <p>Dalam merawat kasur busa, penting untuk memastikan bahwa kasur memiliki dipan yang tepat. Dengan menggunakan box spring atau dipan yang sesuai, bisa menghindari kontak langsung antara kasur busa dengan lantai yang dapat menyebabkan jamuran.</p>
                <br>
                <p style="font-weight: bold;">Sprei yang Rutin Dicuci</p>

                <p>Salah satu cara merawat kasur busa adalah dengan melapisinya dengan sprei dan mencuci sprei tersebut secara rutin. Cuci sprei setidaknya sekali seminggu dengan air panas untuk menghilangkan mikroorganisme yang dapat tumbuh pada sprei yang jarang dicuci.</p>
                <br>
                <p style="font-weight: bold;">Rotasi Kasur Busa untuk Menjaga Bentuknya</p>

                <p>Rotasi kasur 180 derajat setiap enam bulan adalah cara merawat kasur busa yang efektif untuk menjaga bentuknya. Hindari meletakkan benda berat di atas kasur untuk waktu yang lama, agar kasur busa tidak cepat kempes.</p>
                <br>
                <p style="font-weight: bold;">Bersihkan Debu dengan Vacuum Cleaner</p>

                <p>Cara merawat kasur busa dengan menyedot debu menggunakan vacuum cleaner secara rutin. Hal ini tidak hanya menjaga kasur bersih tetapi juga membantu menghilangkan kutu busuk atau kepinding.</p>
                <br>
                <p style="font-weight: bold;">Jemur Kasur Busa dengan Cara yang Tepat</p>

                <p>Menjemur kasur busa di bawah sinar matahari langsung dapat merusaknya. Sebagai alternatif, angin-anginkan kasur di luar ruangan selama 4-5 jam untuk menjaga kelembabannya tanpa merusak teksturnya.</p>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
import {mapActions,mapGetters} from "vuex";
import BaseLayout from "@/components/landing/BaseLayout.vue"
import ProductList from "@/components/product/ProductList.vue"
import ProductLoader from '@/components/loading/product/Content.vue'
import SidebarLoader from '@/components/loading/product/Sidebar.vue'
import Testimonial from "@/components/home/Testimonial.vue";
import Videoshort from "@/components/home/Videoshort.vue";
export default {
    name:"CategorySpringbed",
    components:{
        BaseLayout,
        ProductList,
        ProductLoader,
        Testimonial,
        Videoshort,
        SidebarLoader
    },
    data(){
        return {
            typedText: "",
            textArray: ["anak-anak", "ayah dan ibu", "keluarga Indonesia"],
            typingDelay: 200,
            erasingDelay: 100,
            newTextDelay: 1000,
            textArrayIndex: 0,
            charIndex: 0,
            isTyping: false,
            items: [
                { expanded: false },
                { expanded: false },
                { expanded: false },
                // ... Add more items as needed
            ],
            filterCategories: "",
            filterSearch: "",
            loading:true,
            loadingSidebar:true,
            loadingRelated: false,
            list:{
                more:false,
                total:0,
            },
            statusReset:false,
            filteredBy:this.$store.state.product.list.filtered,
        }
    },
    computed:{
        ...mapGetters("product/list",["products","categories","filterParameters","filtered","categoryGroup","copiedFilter"]),
    },
    methods:{
        ...mapActions("product/list",["fetchProducts","fetchFilterParameters"]),
        filterProduct() {
            this.getProducts(true);
            // console.log();
        },
        async getProducts(firstPage = false,per_page=null){
            this.loading = true;

            if(this.copiedFilter && this.$route.params.filter) this.retrieveCopiedFilter();
            
            var categories = this.filterCategories;
            var search = this.filterSearch;
            
            if(search != ""){
                this.filteredBy.q = search;
            }else{
                this.filteredBy.q = "";
            }

            if(categories != ""){
                this.filteredBy.categories = [categories];
            }else{
                this.filteredBy.categories = [64,65,66];
            }
            this.$store.commit("product/list/setFiltered",this.filteredBy);

            const results = await this.fetchProducts();
            this.statusReset = false;
            this.loading = false;
            if(results.success){
                this.list.total = results.data.total; 
                this.list.more = results.data.pagination.more;
                if(this.list.more) {
                    this.filteredBy.page=this.filteredBy.page+1;
                    this.$store.commit("product/list/setFiltered",{page:this.filteredBy.page});
                }
            }else{
                this.$toast.open({ message: "Gagal mendapatkan data produk", type: "error", duration: 3000, })
            }
            
        },
        type() {
        if (this.charIndex < this.textArray[this.textArrayIndex].length) {
            this.isTyping = true;
            this.typedText += this.textArray[this.textArrayIndex].charAt(this.charIndex);
            this.charIndex++;
            setTimeout(this.type, this.typingDelay);
        } else {
            this.isTyping = false;
            setTimeout(this.erase, this.newTextDelay);
        }
        },
        erase() {
        if (this.charIndex > 0) {
            this.isTyping = true;
            this.typedText = this.textArray[this.textArrayIndex].substring(0, this.charIndex - 1);
            this.charIndex--;
            setTimeout(this.erase, this.erasingDelay);
        } else {
            this.isTyping = false;
            this.textArrayIndex++;
            if (this.textArrayIndex >= this.textArray.length) this.textArrayIndex = 0;
            setTimeout(this.type, this.typingDelay + 1100);
        }
        },
        toggleMenu(selectorName){
            $(selectorName).slideToggle("fast");
        },
        toggleMenuWithSelector(targetSelector){
            targetSelector.slideToggle("fast");
        },
        toggleAccordion(index) {
            for (let i = 0; i < this.items.length; i++) {
                this.items[i].expanded = false;
            }
            this.items[index].expanded = !this.items[index].expanded;
        },
    },
    async mounted(){
        await Promise.all([
            this.getProducts(true),
        ]);
        setTimeout(this.type, this.newTextDelay + 250);

        document.title = 'Jual Springbed & Kasur Busa Harga Terbaik & Berkualitas | MORE Furniture';
        document.description = 'Jual springbed dan kasur busa Olympic Bed dengan kualitas terbaik anti kempes dan bergaransi resmi. Tersedia banyak warna yang bisa dipilih sesuaikan dengan desain kamar tidur.';
    },
    beforeUpdate(){
        if($('.ol-product-type__list').hasClass("slick-initialized slick-slider")){
            $('.ol-product-type__list').slick('unslick');
        }
    },
}
</script>

<style scoped lang="css">

.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 8px 0;
  /* color: #364B7B; */
  font-size: 16px;
  font-weight: bold;
  border: none;
  background: none;
  outline: none;
}
.accordion button:hover, .accordion button:focus {
  cursor: pointer;
  color: #364B7B;
}
.accordion button:hover::after, .accordion button:focus::after {
  cursor: pointer;
  color: #364B7B;
  border: 1px solid #364B7B;
}
.accordion button .accordion-title {
  /* padding: 1em 1.5em 1em 0; */
  display:inline-block;
  width:90%;
}
.accordion button .icon {
  display: inline-block;
  position: absolute;
  /* top: 18px; */
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}
.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}
.accordion button[aria-expanded=true] {
  color: #364B7B;
}
.accordion button[aria-expanded=true] .icon::after {
  width: 0;
}
.accordion button[aria-expanded=true] + .accordion-content {
  opacity: 1;
  max-height: 100%;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content p {
  font-size: 14px;
  font-weight: 300;
  margin-top: 8px;
  margin-bottom: 16px;
}


.animation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin:auto
}
.animation p {
 
  
  font-weight: bold;
  /* letter-spacing: 0.1rem; */
  text-align: center;
  overflow: hidden;
}
.animation p span.typed-text {
  font-weight: bold;
  color: #364b7b;
}
.animation p span.cursor {
  display: inline-block;
  /* background-color: #ccc; */
  margin-left: 0.1rem;
  width: 3px;
  animation: blink 1s infinite;
}
.animation p span.cursor.typing {
  animation: none;
}
@keyframes blink {
  0%  { background-color: #ccc; }
  49% { background-color: #ccc; }
  50% { background-color: transparent; }
  99% { background-color: transparent; }
  100%  { background-color: #ccc; }
}


.info-sertifikat{
    width:50%;
    margin:auto;
}

.info-kelebihan{
    width:70%;
    margin:auto;
}
.text-title{
    font-size:18px;
}

.text-subtitle{
    font-size:24px;
}

.text-header{
    font-size:30px;font-weight: bold;text-align:center;
}

.image-kelebihan{
    width:30%;
}

.image-sertifikat{
    height:100px;
}
.image-sertifikat-1{
    height: 55px;
}

.banner-desktop{
    display:block;
}

.banner-mobile{
    display:none;
}

@media(max-width:767px){
    .info-sertifikat{
        width:100%;    
    }

    .info-kelebihan{
        width:100%;
        margin:auto;
    }

    .text-title{
        font-size:14px;
    }

    .text-subtitle{
        font-size:16px;
    }

    .text-header{
        font-size:20px;font-weight: bold;text-align:center;
    }

    .image-kelebihan{
        width:50%;
    }

    .image-sertifikat{
        height: 60px;
    }

    .image-sertifikat-1{
        height: 35px;
    }

    .banner-desktop{
        display:none;
    }

    .banner-mobile{
        display:block;
    }
}
</style>